.imfloat{
    float:right;
    padding: 5px;
}

.cowSearch{
    height:40px;
    color: #5F6368;
}

.aboutLink{
    background-color: #f2f2f2;
    color: #5F6368;
    padding: 0.5em 1.5em;
    text-decoration: none;
    border: 1px solid #F2F2F2;
    border-radius: 4px;
    white-space: nowrap;
}

.aboutPadding{
    margin-left:10px;
    margin-top:30px;
}

.aboutLink:hover{
    color:#222;
    background-color: #F8F8F8;
    border: 1px solid #C6C6C6;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.home{
	 min-height:100vh;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	flex-direction: column;
 }

.home img{
	width:300px;
}

.search{
	display: flex;
	justify-content: left;
	flex-direction: row;
}

.search img{
	width:200px;
	padding: 5px 0 0 10px;
	margin-right:30px;
}

.tagFive{
	padding: 0;
	margin: 0;
}

.App-Component{
	width: 600px;
    height:50px;
	padding-top:20px;
}

.App-Response{
	border-top:1px solid #E8E8E8;
	margin-left:10px;
	margin-right:10px;
}

.App-Response h3{
	color: #1a0dab;
	font-size:20px;
	line-height: 1.3;
}

.App-Response ul{
	margin-left: 200px;
	margin-right: 200px;
	list-style-type:none;
	padding:5px;
}

.App-Response ul li{
	 display: flex;
	 padding-bottom:20px;
 }

.App-Response ul li .imDiv {
	margin-left: auto;
}

.App-Response ul li img{
	max-height:200px;
    float:right;
    padding: 10px;
}




@media only screen and (max-width: 1500px), (max-device-width: 1500px)
{
	.App-Response ul{
		margin-left: 100px;
		margin-right: 100px;
	}
}

@media only screen and (max-width: 1000px), (max-device-width: 1000px)
{
	.App-Component{
		width: 450px;
	}

}

@media only screen and (max-width: 900px), (max-device-width: 900px)
{
	.App-Response ul li img{
		max-height:150px;
	}
	.App-Response ul{
		margin-left: 10px;
		margin-right: 10px;
	}
	.App-Component{
		width: 350px;
		height:50px;
	}
}

@media only screen and (max-width: 600px), (max-device-width: 600px)
{
	.App-Response ul li img{
		max-height:100px;
	}
	.App-Response ul{
		margin-left: 0;
		margin-right: 0;
	}
    .App-Response{
        margin-top:25px;
    }

	.App-Component{
		width: 400px;

		padding-top:0;
	}
    .aboutPadding{
        margin-left:0;
        margin-top:15px;
    }
	.search{
		flex-direction: column;
		align-items: center;
	}
	.search img{
		width:200px;
		padding: 0;
        margin:0;
	}
}





